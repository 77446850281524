import React from 'react'

const Footer = () => {
  return (
    <footer className="bg-theme-dark text-white py-4">
  <div className="container">
    <div className="row">
      {/* Left Side: Logo and Text */}
      <div className="col-md-8">
        <img src="./img/logoblack.png"  alt="Logo" className="mb-2 footer-img" style={{height: 50}} />
        <div>
          <p className="mb-4 mb-md-0" style={{color: 'grey'}}>
          The Autobreeze fleet is strictly non-smoking. <br />
          Renters and their passengers are not permitted to<br />
          smoke in any Autobreeze car.
          </p>
        </div>
        <div>
          
        </div>
      </div>
      {/* Right Side: Social Media, Contact, and Sitemap */}
      <div className="col-md-4">
        <div className="row">
          <div className="col-4 px-3 mb-4">
            <h6>Sitemap</h6>
            <ul className="list-unstyled">
              <li>
                <a href="#" className="text-decoration-none d-block mb-2" style={{color: 'grey'}}>About Us</a>
              </li>
              <li>
                <a href="#" className="text-decoration-none d-block mb-2" style={{color: 'grey'}}>Terms of Use
                </a>
              </li>
              {/* <li>
                <a href="#" className="text-decoration-none d-block mb-2" style={{color: 'grey'}}>Policies</a>
              </li> */}
              <li>
                <a href="#" className="text-decoration-none d-block mb-2" style={{color: 'grey'}}>Policies</a>
              </li>
            </ul>
          </div>
          {/* Contact */}
          <div className="col-4 px-3 mb-4">
            <h6>Social Media</h6>
            <ul className="list-unstyled">
              <li>
                <a href="https://www.instagram.com/autobreezecar" className="text-decoration-none d-block mb-2" target='_blank'  style={{color: 'grey'}}>Instagram</a>
              </li>
              <li>
                <a href="https://www.facebook.com/profile.php?id=61565346815233" className="text-decoration-none d-block mb-2" target='_blank'  style={{color: 'grey'}}>Facebook</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/autobreeze-car-rental/" className="text-decoration-none d-block mb-2" target='_blank'  style={{color: 'grey'}}>Linkedin</a>
              </li>
              <li>
                <a href="https://www.tiktok.com/@autobreezecars?lang=en" className="text-decoration-none d-block mb-2" target='_blank'  style={{color: 'grey'}}>TikTok</a>
              </li>
            </ul>
          </div>
          <div className="col-4 px-3 mb-4">
            <h6>Contact</h6>
            <ul className="list-unstyled">
              <li>
                <a href="#" className="text-decoration-none d-block mb-2" style={{color: 'grey'}}>Email
                </a>
              </li>
              <li>
                <a href="#" className="text-decoration-none d-block mb-2" style={{color: 'grey'}}>Phone</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12">
      <p className="mt-2" style={{color: 'grey'}}>
      © 2024 Autobreeze. ALL RIGHTS RESERVED. 
          </p>
      </div>
    </div>
  </div>
</footer>


  )
}

export default Footer
